<script>
    import {required} from "@/commons/utils/Rules";
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

    export default {
        name: 'PzBaseForm1',
        components: {},
        mixins: [baseFormFlujo],
        provide: function () {
            return {
                myForm: this
            }
        },
        props: {
            persona: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            var check_Terms = (rule, value, callback) => {
                if (!(value)) {
                    return callback(new Error(this.$t('forms_flujo.accept_terms_and_conditions')));
                } else {
                    return callback();
                }
            };
            return {
                reference: 'formulario1',
                documents: [],
                formulario1: {
                    conditions: {},
                    ref_empresa: this.producto.empresa_ref,
                    clearing: 0,
                    comprobante_domicilio: 1,
                    comprobante_ingresos: 1,
                    accept_advertising: 0
                },
                rules_formulario1: {
                    termYCond: [{validator: check_Terms, trigger: 'change'}],
                    clearing: [required('')],
                    comprobante_domicilio: [required('')],
                    comprobante_ingresos: [required('')],
                },

            }
        },

        created() {
            for (let i = 0; i < this.producto.conditions.length; i++) {
                let condition = this.producto.conditions[i];
                this.formulario1.conditions[condition.slug] = false;
            }
            this.$store.dispatch('loader/up',{trigger:this.$options.name});
            if (!this.producto.needsComprobanteDomicilio) {
                this.rules_formulario1.comprobante_domicilio = []
            }
            if (!this.producto.needsComprobanteIngresos) {
                this.rules_formulario1.comprobante_ingresos = []
            }
            this.$store.dispatch('loader/down',{trigger:this.$options.name});

        },
        methods: {},

    }
</script>


